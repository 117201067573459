<template>
    <div id="app">
        <Header/>
        <router-view class="main"/>
        <Footer :isShow="!isLoggedIn"/>
        <FloatingWhatsappButton/>
    </div>
</template>

<script>
import FloatingWhatsappButton from "@/views/FloatingWhatsappButton";

export default {
  name: 'Roots',
  components: {
    FloatingWhatsappButton,
    Header: () => import('@/components/layout/Header'),
    Footer: () => import('@/components/layout/Footer'),
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    }
        },
        // created() {
        //     this.$http.interceptors.response.use(undefined, function (err) {
        //         return new Promise(function (resolve, reject) {
        //             if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        //                 this.$store.dispatch(logout)
        //             }
        //             throw err;
        //         });
        //     });
        // }
    }
</script>
