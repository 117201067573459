import Vue from 'vue'
import Vuex from 'vuex'
import {securedAxiosInstance} from '@/helper/api/axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginData: JSON.parse(localStorage.getItem('user')) || null,
        answerData: []
    },
    mutations: {
        LOGIN_MUTATION(state, loginData) {
            state.loginData = loginData;
        }
    },
    actions: {
        loginAction({commit}, userLoginForm) {
            return new Promise((resolve, reject) => {
                const {username, password} = userLoginForm;
                securedAxiosInstance.post('participant-login', {
                    username, password
                }).then(res => {
                    const {data: {data}} = res;
                    localStorage.setItem('user', JSON.stringify(data));
                    commit('LOGIN_MUTATION', data);
                    resolve(res);
                }).catch(e => {
                    console.log(e);
                    localStorage.removeItem('user');
                    reject(e);
                });
            })
        },
        registerAction({commit}, userLoginForm) {
            return new Promise((resolve, reject) => {
                const {username, password, name, email} = userLoginForm;
                securedAxiosInstance.post('participant-register', {
                    username, password, name, email
                }).then(res => {
                    const {data: {data}} = res;
                    localStorage.setItem('user', JSON.stringify(data));
                    commit('LOGIN_MUTATION', data);
                    resolve(res);
                }).catch(e => {
                    console.log(e);
                    localStorage.removeItem('user');
                    reject(e);
                });
            })
        },
        logoutAction({commit}) {
            return new Promise((resolve) => {
                commit('LOGIN_MUTATION', null);
                localStorage.removeItem('answers-temp');
                localStorage.removeItem('test-data');
                localStorage.removeItem('current-section');
                localStorage.removeItem('user');
                localStorage.removeItem('gender');
                resolve();
            })
        }
    },
    getters : {
        isLoggedIn: state => !!state.loginData,
    },
    modules: {}
})
