import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('@/views/EventsV2.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/News.vue')
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import('@/views/Clients.vue')
    },
    //assessment
    {
        path: '/assessment/education',
        name: 'assessment-education',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/assessment/business',
        name: 'assessment-business',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/assessment/family',
        name: 'assessment-family',
        component: () => import('@/views/Detail.vue')
    },
    //conseling
    {
        path: '/conseling/education',
        name: 'counselling-education',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/conseling/business',
        name: 'counselling-business',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/conseling/family',
        name: 'counselling-family',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/conseling/others',
        name: 'counselling-others',
        component: () => import('@/views/Detail.vue')
    },
    //terapi
    {
        path: '/therapy/individual',
        name: 'therapy-individual',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/therapy/group',
        name: 'therapy-group-organization',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/therapy/family',
        name: 'therapy-family',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/therapy/others',
        name: 'therapy-others',
        component: () => import('@/views/Detail.vue')
    },
    //training
    {
        path: '/training/education',
        name: 'training-education-training',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/training/business',
        name: 'training-business-training',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/training/family',
        name: 'training-family-training',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/training/others',
        name: 'training-others',
        component: () => import('@/views/Detail.vue')
    },
    {
        path: '/success-stories/my-story',
        name: 'my-story',
        component: () => import('@/views/DetailSuccessStories.vue')
    },
    {
        path: '/success-stories/your-story',
        name: 'your-story',
        component: () => import('@/views/DetailSuccessStories.vue')
    },
    {
        path: '/success-stories/our-story',
        name: 'our-story',
        component: () => import('@/views/DetailSuccessStories.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register')
    },
    {
        path: '/logout',
        name: 'logout',
    },
    {
        path: '/test/:uuid',
        name: 'test',
        component: () => import('@/views/CheckLink')
    },
    {
        path: '/section/quiz/:sectionId',
        name: 'section',
        component: () => import('@/views/QuizSection'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/section/finish',
        name: 'finish-quiz',
        component: () => import('@/views/QuizFinish'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '*',
        name: '404',
        component: () => import('@/views/404')
    },
    //FREE TEST
    {
        path: '/free-test',
        name: 'free-test',
        component: () => import('@/views/freeTest/FirstScreen')
    },
    {
        path: '/free-test/instruction',
        name: 'instruction',
        component: () => import('@/views/freeTest/Instruction')
    },
    {
        path: '/free-test/quiz',
        name: 'quiz-section',
        component: () => import('@/views/freeTest/QuizSection')
    },
    {
        path: '/free-test/user-info',
        name: 'user-info',
        component: () => import('@/views/freeTest/UserInfo')
    },
    //MBTI RESULT
    {
        path: '/free-test/enfj',
        name: 'ENFJ',
        component: () => import('@/views/freeTest/MBTI/ENFJ')
    },
    {
        path: '/free-test/intp',
        name: 'INTP',
        component: () => import('@/views/freeTest/MBTI/INTP')
    },
    {
        path: '/free-test/enfp',
        name: 'ENFP',
        component: () => import('@/views/freeTest/MBTI/ENFP')
    },
    {
        path: '/free-test/intj',
        name: 'INTJ',
        component: () => import('@/views/freeTest/MBTI/INTJ')
    },
    {
        path: '/free-test/entj',
        name: 'ENTJ',
        component: () => import('@/views/freeTest/MBTI/ENTJ')
    },
    {
        path: '/free-test/infp',
        name: 'INFP',
        component: () => import('@/views/freeTest/MBTI/INFP')
    },
    {
        path: '/free-test/entp',
        name: 'ENTP',
        component: () => import('@/views/freeTest/MBTI/ENTP')
    },
    {
        path: '/free-test/infj',
        name: 'INFJ',
        component: () => import('@/views/freeTest/MBTI/INFJ')
    },
    {
        path: '/free-test/esfj',
        name: 'ESFJ',
        component: () => import('@/views/freeTest/MBTI/ESFJ')
    },
    {
        path: '/free-test/isfj',
        name: 'ISFJ',
        component: () => import('@/views/freeTest/MBTI/ISFJ')
    },
    {
        path: '/free-test/esfp',
        name: 'ESFP',
        component: () => import('@/views/freeTest/MBTI/ESFP')
    },
    {
        path: '/free-test/isfp',
        name: 'ISFP',
        component: () => import('@/views/freeTest/MBTI/ISFP')
    },
    {
        path: '/free-test/estj',
        name: 'ESTJ',
        component: () => import('@/views/freeTest/MBTI/ESTJ')
    },
    {
        path: '/free-test/istj',
        name: 'ISTJ',
        component: () => import('@/views/freeTest/MBTI/ISTJ')
    },
    {
        path: '/free-test/estp',
        name: 'ESTP',
        component: () => import('@/views/freeTest/MBTI/ESTP')
    },
    {
        path: '/free-test/istp',
        name: 'ISTP',
        component: () => import('@/views/freeTest/MBTI/ISTP')
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/login')
    } else {
        next();
    }
})

export default router
