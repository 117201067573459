<template>
  <a href="https://api.whatsapp.com/send?phone=+6281222313551&text=" target="_blank" class="whatsapp-button">
    <button
        class="btn pmd-btn-fab pmd-btn-raised"
        style="background-color: #1bd741;
            box-shadow: 0px 5px 18px #1bd741;
            position: fixed;
            bottom: 30px;
            right: 30px;
            z-index: 999;
            cursor: pointer"
        type="button"
    >
      Need Help? Let's chat with us! &nbsp; &nbsp;
      <img alt="" height="20" src="../assets/whatsapp.png" style="margin-left: 6px" width="20">
    </button>
  </a>
</template>

<script>
export default {
  name: "FloatingWhatsappButton"
}
</script>

<style scoped>

</style>