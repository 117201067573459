import axios from 'axios'

const securedAxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

const plainAxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

securedAxiosInstance.interceptors.request.use(config => {
    const method = config.method.toUpperCase()
    if (method !== 'OPTIONS') {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${localStorage.token}`
        }
    }
    return config
})

securedAxiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.config && error.response.status === 401) {
        // In case 401 is caused by expired access cookie - we'll do refresh request
        return plainAxiosInstance.post('/refresh', {}, {headers: {'Authorization': localStorage.token}})
            .then(response => {
                localStorage.token = response.data.token
                localStorage.signedIn = true
                // And after successful refresh - repeat the original request
                let retryConfig = error.response.config
                retryConfig.headers['Authorization'] = localStorage.token
                return plainAxiosInstance.request(retryConfig)
            }).catch(error => {
                delete localStorage.token
                delete localStorage.signedIn
                // redirect to signin in case refresh request fails
                location.replace('/login')
                return Promise.reject(error)
            })
    } else {
        return Promise.reject(error)
    }
})

export {securedAxiosInstance, plainAxiosInstance}