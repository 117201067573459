import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAxios from 'vue-axios'
import {plainAxiosInstance, securedAxiosInstance} from './helper/api/axios'

import store from './store';
import jQuery from 'jquery';

import moment from 'moment';

import VueGtag from "vue-gtag";

import VueSocialSharing from 'vue-social-sharing'

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import * as VueGoogleMaps from 'vue2-google-maps'
import 'popper.js'
import 'bootstrap';
import './styles/themes.scss';
import './icons.js';

global.$ = global.jQuery = jQuery;

Vue.config.productionTip = false
Vue.prototype.$axios = securedAxiosInstance;

Vue.use(VueAxios, {
    secured: securedAxiosInstance,
    plain: plainAxiosInstance
});

Vue.use(VueGtag, {
  config: {
    id: "GTM-KVLT5JP",
  }
}, router);

Vue.use(VueSocialSharing);

Vue.filter('date', function (date) {
    moment.locale('id');
    return moment(date).format('MMM D, Y');
});

Vue.filter('time12', function (date) {
    return moment(date).format('hh:mm a');
});

Vue.filter('dateOnly', function (date) {
    return moment(date).format('D');
});

Vue.filter('monthOnly', function (date) {
    return moment(date).format('MMM');
});

Vue.component('flatPickr', flatPickr);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDsV7UpBOXzqNQtO4wL_kSRGAm6cYoZ2UM',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})

new Vue({
    router,
    store,
    securedAxiosInstance,
    plainAxiosInstance,
    render: h => h(App)
}).$mount('#app')